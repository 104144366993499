import {
  ApolloClient,
  from,
  type ApolloLink,
  createHttpLink,
  type HttpOptions,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';
import { v4 as uuidv4 } from 'uuid';

const X_REQUEST_ID = 'x-request-id';
const AUTHORIZATION = 'authorization';

const createAuthLink = () =>
  setContext(async (_, prevContext) => ({
    prevContext,
    headers: {
      ...prevContext.headers,
      [AUTHORIZATION]: prevContext.token.startsWith('Bearer')
        ? `${prevContext.token}`
        : `Bearer ${prevContext.token}`,
    },
  }));

const createRequestIdLink = () =>
  setContext(async (_, prevContext) => ({
    ...prevContext,
    headers: {
      ...prevContext.headers,
      [X_REQUEST_ID]: uuidv4(),
    },
  }));

const createApiHttpLink = (
  uri: string,
  credentials: HttpOptions['credentials'],
) =>
  createHttpLink({
    uri,
    credentials,
    fetch,
  });

export interface GraphqlClientConfig {
  apiUri: string;
  cache?: InMemoryCache;
  credentials: HttpOptions['credentials'];
  createContextLink: () => ApolloLink;
}

const createGraphqlClient = (config: GraphqlClientConfig) => {
  const { apiUri, cache = new InMemoryCache(), createContextLink } = config;
  const links: ApolloLink[] = [
    createAuthLink(),
    createRequestIdLink(),
    createContextLink(),
    createApiHttpLink(apiUri, config.credentials),
  ];

  return new ApolloClient({
    cache,
    link: from(links),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });
};

export default createGraphqlClient;
