
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0Ijoi4Lij4Lix4LiB4Lip4Liy4LiE4Lin4Liy4Lih4Lib4Lil4Lit4LiU4Lig4Lix4Lii4Lia4Lix4LiN4LiK4Li1IFNFRUsg4LiC4Lit4LiH4LiE4Li44LiT4LmC4LiU4Lii4LiB4Liy4Lij4LiV4Lix4LmJ4LiH4LiE4LmI4Liy4LiB4Liy4Lij4Lii4Li34LiZ4Lii4Lix4LiZ4LiV4Lix4Lin4LiV4LiZ4LmC4LiU4Lii4LmD4LiK4LmJ4Lir4Lil4Liy4Lii4Lib4Lix4LiI4LiI4Lix4LiiIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IuC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC4leC5iOC4rSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0Ijoi4Lij4Lix4LiB4Lip4Liy4LiE4Lin4Liy4Lih4Lib4Lil4Lit4LiU4Lig4Lix4Lii4Lia4Lix4LiN4LiK4Li1IFNFRUsg4LiC4Lit4LiH4LiE4Li44LiT4LmC4LiU4Lii4LiB4Liy4Lij4LiV4Lix4LmJ4LiH4LiE4LmI4Liy4LiB4Liy4Lij4Lii4Li34LiZ4Lii4Lix4LiZ4LiV4Lix4Lin4LiV4LiZ4LmC4LiU4Lii4LmD4LiK4LmJ4Lir4Lil4Liy4Lii4Lib4Lix4LiI4LiI4Lix4LiiIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IuC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC4leC5iOC4rSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiQW1hbmthbiBha3VuIFNFRUsgQW5kYSBkZW5nYW4gbWVuZ2F0dXIgYXV0ZW50aWthc2kgbXVsdGlmYWt0b3IiLCJtZmFfdGlja2V0X19jb250aW51ZV9fYnV0dG9uIjoiTGFuanV0a2FuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiQW1hbmthbiBha3VuIFNFRUsgQW5kYSBkZW5nYW4gbWVuZ2F0dXIgYXV0ZW50aWthc2kgbXVsdGlmYWt0b3IiLCJtZmFfdGlja2V0X19jb250aW51ZV9fYnV0dG9uIjoiTGFuanV0a2FuIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0Ijoi4Lij4Lix4LiB4Lip4Liy4LiE4Lin4Liy4Lih4Lib4Lil4Lit4LiU4Lig4Lix4Lii4Lia4Lix4LiN4LiK4Li1IFNFRUsg4LiC4Lit4LiH4LiE4Li44LiT4LmC4LiU4Lii4LiB4Liy4Lij4LiV4Lix4LmJ4LiH4LiE4LmI4Liy4LiB4Liy4Lij4Lii4Li34LiZ4Lii4Lix4LiZ4LiV4Lix4Lin4LiV4LiZ4LmC4LiU4Lii4LmD4LiK4LmJ4Lir4Lil4Liy4Lii4Lib4Lix4LiI4LiI4Lix4LiiIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IuC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC4leC5iOC4rSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0Ijoi4Lij4Lix4LiB4Lip4Liy4LiE4Lin4Liy4Lih4Lib4Lil4Lit4LiU4Lig4Lix4Lii4Lia4Lix4LiN4LiK4Li1IFNFRUsg4LiC4Lit4LiH4LiE4Li44LiT4LmC4LiU4Lii4LiB4Liy4Lij4LiV4Lix4LmJ4LiH4LiE4LmI4Liy4LiB4Liy4Lij4Lii4Li34LiZ4Lii4Lix4LiZ4LiV4Lix4Lin4LiV4LiZ4LmC4LiU4Lii4LmD4LiK4LmJ4Lir4Lil4Liy4Lii4Lib4Lix4LiI4LiI4Lix4LiiIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IuC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC4leC5iOC4rSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiQW1hbmthbiBha3VuIFNFRUsgQW5kYSBkZW5nYW4gbWVuZ2F0dXIgYXV0ZW50aWthc2kgbXVsdGlmYWt0b3IiLCJtZmFfdGlja2V0X19jb250aW51ZV9fYnV0dG9uIjoiTGFuanV0a2FuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiQW1hbmthbiBha3VuIFNFRUsgQW5kYSBkZW5nYW4gbWVuZ2F0dXIgYXV0ZW50aWthc2kgbXVsdGlmYWt0b3IiLCJtZmFfdGlja2V0X19jb250aW51ZV9fYnV0dG9uIjoiTGFuanV0a2FuIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwibWZhX3RpY2tldF9fY29udGludWVfX2J1dHRvbiI6IkNvbnRpbnVlIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiW8Wg4bq94bq94bq9w6fHmseax5rFmeG6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5rFmSDFoMOLw4vhuLAgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5ryDDn8O9w73DvSDFoeG6veG6veG6veG5r%2BG5r8Osw6zDrOC4geC4teC5icSjIMeax5rHmsalIG3Mgseax5rHmsaa4bmvw6zDrMOsLcaSxIPEg8SDw6fhua%2FDtsO2w7bFmSDEg8SDxIPHmseax5rhua%2FhuKnhur3hur3hur3guIHguLXguYnhua%2FDrMOsw6zDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iV0iLCJtZmFfdGlja2V0X19jb250aW51ZV9fYnV0dG9uIjoiW8OHw7bDtuC4geC4teC5ieG5r8Osw6zguIHguLXguYnHmsea4bq94bq9XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtZmFfdGlja2V0X19ib2R5X190ZXh0IjoiW8Wg4bq94bq94bq9w6fHmseax5rFmeG6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5rFmSDFoMOLw4vhuLAgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5ryDDn8O9w73DvSDFoeG6veG6veG6veG5r%2BG5r8Osw6zDrOC4geC4teC5icSjIMeax5rHmsalIG3Mgseax5rHmsaa4bmvw6zDrMOsLcaSxIPEg8SDw6fhua%2FDtsO2w7bFmSDEg8SDxIPHmseax5rhua%2FhuKnhur3hur3hur3guIHguLXguYnhua%2FDrMOsw6zDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iV0iLCJtZmFfdGlja2V0X19jb250aW51ZV9fYnV0dG9uIjoiW8OHw7bDtuC4geC4teC5ieG5r8Osw6zguIHguLXguYnHmsea4bq94bq9XSJ9!"
        )
      )
      });
  
      export { translations as default };
    