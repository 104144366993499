import { useTranslations } from '@vocab/react';
import {
  Box,
  Stack,
  Text,
  TextLink,
  Accordion,
  AccordionItem,
  List,
  Card,
} from 'braid-design-system';
import React from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import type { AppLanguage, Locale } from 'src/types';
import getClientHrefLink from 'src/utils/getClientHrefLink';
import { getLanguagePrefixedPath } from 'src/utils/getLanguage';

import translations from './.vocab';

type Link = Record<string, Record<string, string>>;

const getSetupAccountLink = (
  language: AppLanguage,
  locale: Locale = 'au',
): string => {
  const laguagePrefixedPath = getLanguagePrefixedPath(
    language,
    '/s/article/How-do-I-add-new-users-or-update-user-permissions',
  );
  const asiaEnglishLink = `https://help.${locale}.employer.seek.com${laguagePrefixedPath}`;
  const auEnglishLink = `https://help.seek.com.au/employer${laguagePrefixedPath}`;
  const nzEnglishLink = `https://help.seek.co.nz/employer${laguagePrefixedPath}`;

  const urls: Link = {
    en: {
      hk: asiaEnglishLink,
      ph: asiaEnglishLink,
      my: asiaEnglishLink,
      sg: asiaEnglishLink,
      id: asiaEnglishLink,
      th: `${asiaEnglishLink}?language=en_US`,
      au: auEnglishLink,
      nz: nzEnglishLink,
    },
    id: {
      id: `${asiaEnglishLink}?language=in`,
    },
    th: {
      th: `${asiaEnglishLink}?language=th`,
    },
  };

  return urls[language][locale] || auEnglishLink;
};

const SecondFactorFAQ = () => {
  const context = useAppContext();
  const { language, locale } = context;

  const { t } = useTranslations(translations);

  return (
    <Box paddingBottom="medium" paddingTop="medium" marginTop="large">
      <Card>
        <Stack space="small">
          <Box paddingBottom="medium">
            <Text size="large" weight="strong">
              {t('mfa_faq__title__text')}
            </Text>
          </Box>
          <Accordion
            size="standard"
            space="large"
            weight="regular"
            dividers={false}
          >
            <AccordionItem
              id="second_factor_faq_1"
              label={t('mfa_faq__email_not_received_title__text')}
            >
              <Box
                marginTop="small"
                paddingRight={{
                  mobile: 'medium',
                  tablet: 'medium',
                  desktop: 'large',
                }}
              >
                <Stack space="large">
                  <Text tone="secondary">
                    {t('mfa_faq__email_not_received_summary__text')}
                  </Text>
                  <Box paddingLeft="medium">
                    <Stack space="large">
                      <List space="large">
                        <Stack space="small">
                          <Text tone="secondary" weight="strong">
                            {t('mfa_faq__email_not_received_body_1__text')}
                          </Text>
                          <Text tone="secondary">
                            {t('mfa_faq__email_not_received_body_2__text')}
                          </Text>
                        </Stack>
                        <Stack space="small">
                          <Text tone="secondary" weight="strong">
                            {t('mfa_faq__email_not_received_body_3__text')}
                          </Text>
                          <Text tone="secondary">
                            {t('mfa_faq__email_not_received_body_4__text')}
                          </Text>
                        </Stack>
                        <Stack space="small">
                          <Text tone="secondary" weight="strong">
                            {t('mfa_faq__email_not_received_body_5__text')}
                          </Text>
                          <Text tone="secondary">
                            {t('mfa_faq__email_not_received_body_6__text')}
                          </Text>
                        </Stack>
                      </List>
                      <List space="small">
                        <Text tone="secondary">
                          {t('mfa_faq__email_not_received_body_7__text')}
                        </Text>
                        <Text tone="secondary">
                          Australia:{' '}
                          <TextLink href="mailto:customerservice-au@seek.com">
                            customerservice-au@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          New Zealand:{' '}
                          <TextLink href="mailto:customerservice-nz@seek.com">
                            customerservice-nz@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          Hong Kong:{' '}
                          <TextLink href="mailto:customerservice-hk@seek.com">
                            customerservice-hk@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          Indonesia:{' '}
                          <TextLink href="mailto:customerservice-id@seek.com">
                            customerservice-id@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          Philippines:{' '}
                          <TextLink href="mailto:customerservice-ph@seek.com">
                            customerservice-ph@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          Malaysia:{' '}
                          <TextLink href="mailto:customerservice-my@seek.com">
                            customerservice-my@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          Singapore:{' '}
                          <TextLink href="mailto:customerservice-sg@seek.com">
                            customerservice-sg@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          Thailand:{' '}
                          <TextLink href="mailto:customerservice-th@seek.com">
                            customerservice-th@seek.com
                          </TextLink>
                        </Text>
                      </List>
                      <List space="large">
                        <Stack space="small">
                          <Text tone="secondary">
                            {t('mfa_faq__email_not_received_body_8__text')}
                          </Text>
                          <Text tone="secondary">
                            {t('mfa_faq__email_not_received_body_9__text')}
                          </Text>
                        </Stack>
                        <Stack space="small">
                          <Text tone="secondary" weight="strong">
                            {t('mfa_faq__email_not_received_body_10__text')}
                          </Text>
                          <Text tone="secondary">
                            {t('mfa_faq__email_not_received_body_11__text')}
                          </Text>
                        </Stack>
                      </List>
                    </Stack>
                  </Box>
                  <Text tone="secondary">
                    {t('mfa_faq__email_not_received_body_12__text', {
                      CustomerServiceLink: (v) => (
                        <TextLink
                          href={getClientHrefLink(context, false, '/contactus')}
                          target="_blank"
                        >
                          {v}
                        </TextLink>
                      ),
                    })}
                  </Text>
                </Stack>
              </Box>
            </AccordionItem>
            <AccordionItem
              id="second_factor_faq_2"
              label={t('mfa_faq__email_in_member_account__title')}
            >
              <Box
                marginTop="small"
                paddingRight={{
                  mobile: 'medium',
                  tablet: 'medium',
                  desktop: 'large',
                }}
              >
                <Stack space="large">
                  <Text tone="secondary">
                    {t('mfa_faq__email_in_member_account_body_1__text')}
                  </Text>
                  <Text tone="secondary">
                    {t('mfa_faq__email_in_member_account_body_2__text')}
                  </Text>
                  <Text tone="secondary">
                    {t('mfa_faq__email_in_member_account_body_3__text')}{' '}
                    {t('mfa_faq__email_in_member_account_body_4__text')}
                  </Text>
                  <Text tone="secondary">
                    {t('mfa_faq__email_in_member_account_body_5__text', {
                      SetUpAccountLink: (v) => (
                        <TextLink
                          href={getSetupAccountLink(language, locale)}
                          target="_blank"
                        >
                          {v}
                        </TextLink>
                      ),
                    })}
                  </Text>
                </Stack>
              </Box>
            </AccordionItem>
            <AccordionItem
              id="second_factor_faq_3"
              label={t('mfa_faq__email_inbox_no_access__title')}
            >
              <Box
                marginTop="small"
                paddingRight={{
                  mobile: 'medium',
                  tablet: 'medium',
                  desktop: 'large',
                }}
              >
                <Stack space="large">
                  <Text tone="secondary">
                    {t('mfa_faq__email_inbox_no_access_body_1__text', {
                      CustomerServiceLink: (v) => (
                        <TextLink
                          href={getClientHrefLink(context, false, '/contactus')}
                          target="_blank"
                        >
                          {v}
                        </TextLink>
                      ),
                    })}
                    <br />
                    {t('mfa_faq__email_inbox_no_access_body_2__text')}
                  </Text>
                  <Text tone="secondary">
                    {t('mfa_faq__email_inbox_no_access_body_3__text', {
                      ProtectOnlineLink: (v) => (
                        <TextLink
                          href={getClientHrefLink(
                            context,
                            false,
                            '/protect-yourself-online',
                          )}
                          target="_blank"
                        >
                          {v}
                        </TextLink>
                      ),
                    })}
                  </Text>
                </Stack>
              </Box>
            </AccordionItem>
          </Accordion>
        </Stack>
      </Card>
    </Box>
  );
};
export default SecondFactorFAQ;
