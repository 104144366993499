import { useTranslations } from '@vocab/react';
import {
  Box,
  Heading,
  Hidden,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';
import React from 'react';

import Header from 'src/components/shared/Header/Header';

import SecondfactorLockIllo from '../../shared/SecondFactorEnrolment/SecondfactorLockIllo';

import translations from './.vocab';
import SecondFactorFAQ from './SecondFactorFAQ';

import * as styles from './SecondFactorEnrolment.css';

interface Props {
  email?: string;
  differentAccountLink?: boolean;
}

/**
 * a shared UI between the components for 2FA pages for suspicious/non-suspicious users
 */
const SecondFactorEnrolmentUI = ({
  email,
  differentAccountLink = true,
}: Props) => {
  const { t } = useTranslations(translations);

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      width="full"
      height="full"
      paddingBottom="large"
      className={styles.container}
    >
      <Header />
      <Box
        display="flex"
        justifyContent={{
          mobile: 'center',
          tablet: 'center',
          desktop: 'center',
        }}
        position="relative"
        padding={{
          mobile: 'large',
          tablet: 'large',
          desktop: 'xlarge',
        }}
        paddingTop={{
          mobile: 'large',
          tablet: 'medium',
          desktop: 'small',
        }}
        flexDirection="column"
        className={styles.content}
      >
        <Stack space="large">
          <Box
            background="surface"
            paddingTop={{
              mobile: 'medium',
              tablet: 'large',
              desktop: 'large',
            }}
            paddingBottom="large"
            paddingRight={{
              mobile: 'medium',
              tablet: 'small',
              desktop: 'small',
            }}
            paddingLeft="medium"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Hidden below="tablet">
              <Box padding="medium" paddingRight="large">
                <SecondfactorLockIllo />
              </Box>
            </Hidden>
            <Stack space="large">
              <Heading align="center" level="4">
                {t('mfa_enrollment__heading__text')}
              </Heading>
              <Stack space="small">
                <Text size="small" align="center">
                  {t('mfa_enrollment__body_1__text')}
                </Text>
                <Text size="small" align="center">
                  {t('mfa_enrollment__body_2__text')}
                </Text>
                <Text size="small" align="center">
                  {t('mfa_enrollment__body_3_1__text')} <strong>{email}</strong>{' '}
                  {t('mfa_enrollment__body_3_2__text')}
                </Text>
              </Stack>
            </Stack>
          </Box>
          {differentAccountLink && (
            <Text align="center">
              <TextLink href="/oauth/logout">
                {t('mfa_enrollment__body__logout__link')}
              </TextLink>
            </Text>
          )}
          <SecondFactorFAQ />
        </Stack>
      </Box>
    </Box>
  );
};
export default SecondFactorEnrolmentUI;
