import { track } from 'src/modules/tracking/trackJs';
import type { AppLanguage } from 'src/types';
import { displayError } from 'src/utils/displayError';

export const handleError = (e: Error | any, language: AppLanguage = 'en') => {
  const error = e.message || e.error?.code || e;

  let message: string;
  switch (true) {
    case error.includes('user is blocked'):
      message = 'error.user_is_blocked';
      break;
    case error.includes('No MFA factors enabled for enrolment'):
      message = 'error.no_mfa_factors';
      break;
    case error.includes('Expiration Time (exp) claim error in the ID token'):
      message = 'error.expiration_time_claim';
      break;
    case error.includes('There are no query params'):
      message = 'error.no_query_params';
      break;
    case error === 'consent_required':
      message = 'error.consent_required';
      break;
    case error === 'Invalid state':
      message = 'error.invalid_state';
      break;
    case error.includes('social_provider_email_required'):
      message = 'error.social_provider_email_required';
      break;
    case error === 'apple_signin_account_required':
      message = 'error.apple_signin_account_required';
      break;
    case error === 'email_verification_required':
      message = 'error.email_verification_required';
      break;
    case error === 'impersonation_failed_unauthorized_seek_network_required':
      message = 'error.impersonation_failed_unauthorized_seek_network_required';
      break;
    case error === 'impersonation_failed_unauthorized':
      message = 'error.impersonation_failed_unauthorized';
      break;
    case error === 'impersonation_failed_selfImpersonation':
      message = 'error.impersonation_failed_selfImpersonation';
      break;
    case error === 'impersonation_failed_testUser':
      message = 'error.impersonation_failed_testUser';
      break;
    case error === 'impersonation_failed_userNotFound':
      message = 'error.impersonation_failed_userNotFound';
      break;
    case error === 'force_email_verififcation_rule_generic_error':
      message = 'error.force_email_verififcation_rule_generic_error';
      break;
    case error === 'login_not_allowed':
      message = 'error.login_not_allowed';
      break;
    case error === 'passwordless_signin_account_required':
      message = 'error.passwordless_signin_account_required';
      break;
    default:
      message = 'error.default';
      break;
  }

  track(`originalError: ${error} - errorCode: ${message}`);
  displayError(language, message);
};
