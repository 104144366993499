import type { Brand, Country } from '@seek/melways-sites';
import getDisplayBrand from '@seek/online-identity-auth0-hosted-pages/src/utils/getDisplayBrand';
import { useTranslations } from '@vocab/react';
import {
  Box,
  CheckboxStandalone,
  Columns,
  Column,
  Text,
  IconCritical,
  TextLink,
} from 'braid-design-system';
import { type FormEvent, useEffect } from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import { useAnalyticsContext } from 'src/modules/tracking/analytics/AnalyticsContext';
import getClientHrefLink from 'src/utils/getClientHrefLink';

import translations from './.vocab';
import type { ConsentOptionChangeTracking, ConsentTypeProps } from './utils';

interface Props {
  consentProps: ConsentTypeProps;
  controller: any;
  isMobileClient: boolean;
  onCheckboxOptionChangeTracking: (
    options: ConsentOptionChangeTracking,
  ) => void;
}

const ConsentCheckbox = ({
  controller,
  consentProps,
  isMobileClient,
  onCheckboxOptionChangeTracking,
}: Props) => {
  const { t } = useTranslations(translations);
  const context = useAppContext();
  const { analytics } = useAnalyticsContext();
  const { id, name, type } = consentProps;
  const { locale } = context;

  const privacyLink = getClientHrefLink(context, isMobileClient, '/privacy');

  useEffect(() => {
    if (controller.errorMessage) {
      analytics.trackLink({
        eventName: 'alert_viewed',
        currentPage: 'social-create-account-confirmation',
        alertShownType: 'notice',
        alertShownTone: 'critical',
        alertShownPosition: 'content',
        alertShownTitle: 'consent check box unselected',
        alertShownMessage: controller.errorMessage,
      });
    }
  }, [analytics, controller.errorMessage]);

  const onConsentChange = (event: FormEvent<HTMLInputElement>) => {
    controller.onChange(event);

    // this will not track age consent
    onCheckboxOptionChangeTracking({
      eventName: 'option_selection_pressed',
      currentPage: 'social-create-account-confirmation',
      optionSelectionType: 'checkbox',
      optionSelection: controller.value ? 'unchecked' : 'checked',
      optionSelectionName: name,
      [id]: !controller.value,
    });
  };

  const brand = context.hostConfig.clientConfig.brand as Exclude<Brand, 'seek'>;
  const consentKey: () => Parameters<typeof t>[0] = () => {
    if (brand === 'jobsdb' && type === 'privacy' && locale === 'hk') {
      return `registration__privacy_consent_hk__checkbox`;
    }

    return `registration__${type}_consent__checkbox`;
  };

  const AGE_CONSENT_PER_LOCALE: Partial<Record<Country, number>> = {
    id: 21,
    th: 20,
    sg: 13,
    my: 18,
    ph: 18,
  };

  const getAgeConsent = (siteLocale: Country): number | '' =>
    AGE_CONSENT_PER_LOCALE[siteLocale] ?? '';

  const consentText = t(consentKey(), {
    PrivacyPolicyLink: (v: any) => (
      <TextLink
        href={privacyLink}
        weight="weak"
        target="_blank"
        onClick={() =>
          analytics.trackLink({
            currentPage: 'social-create-account-confirmation',
            eventName: 'privacy_statement_pressed',
            isThirdPartyLink: 'false',
            linkPosition: 'body',
          })
        }
      >
        {v}
      </TextLink>
    ),
    parentalConsentAge: getAgeConsent(locale as Country).toString(),
    siteName: getDisplayBrand(brand),
  });

  return (
    <Box>
      <Columns space="small">
        <Column width="content">
          <CheckboxStandalone
            aria-labelledby={`${type}_agreement`}
            size="small"
            id={`${type}-chkbox`}
            tone={Boolean(controller.valid) ? 'neutral' : 'critical'}
            required
            checked={controller.value}
            onChange={(e) => {
              onConsentChange(e);
              controller.onBlur(e);
            }}
          />
        </Column>
        <Column>
          <Box
            component="label"
            htmlFor={`${type}-chkbox`}
            aria-label={`${type}_agreement`}
            cursor="pointer"
          >
            <Text size="small" id={`${type}_agreement`}>
              {consentText}
            </Text>
          </Box>
        </Column>
      </Columns>
      {!Boolean(controller.valid) && (
        <Box paddingTop={['medium']}>
          <Text size="small" tone="critical">
            <IconCritical /> &nbsp;
            {controller.errorMessage}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default ConsentCheckbox;
