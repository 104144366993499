import 'braid-design-system/reset';
import type { Language } from '@seek/melways-sites';
import { VocabProvider } from '@vocab/react';
import { IntercomProvider } from 'react-use-intercom';

import type { RenderConfig, SiteName } from 'src/modules/configuration';
import type { Locale, BraidThemeName } from 'src/types';
import { isBrowser } from 'src/utils/device';
import {
  getLanguagePrefixedPath,
  supportedLanguages,
} from 'src/utils/getLanguage';
import { INTERCOM_APP_ID } from 'src/utils/intercom';
import type { Auth0TenantName } from 'src/utils/tenants';

import AppContext from './AppContext';
import { AppRouter } from './AppRouter';
import { BraidDesignProvider } from './BraidDesignProvider';

const REDIRECT_PATH = '/oauth/callback/';

interface AppProps {
  language: string;
  site: SiteName;
  tenant: Auth0TenantName;
  environment: string;
  config: RenderConfig;
}

export default ({
  language: vocabLanguage,
  site,
  tenant,
  environment,
  config,
}: AppProps) => {
  const [appLanguage, appLocale] = vocabLanguage.split('-');
  const language = supportedLanguages[appLanguage as Language];
  const locale = appLocale?.toLowerCase() as Locale | undefined;

  if (!isBrowser()) {
    return null;
  }

  const hostConfig = config.hostConfigs[window.location.hostname];

  if (environment !== 'production') {
    hostConfig.clientConfig.clientDomain = window.location.host;
    hostConfig.clientConfig.redirectPath = REDIRECT_PATH;
  }

  if (hostConfig.clientConfig.redirectPath === REDIRECT_PATH) {
    hostConfig.clientConfig.redirectPath = getLanguagePrefixedPath(
      language,
      REDIRECT_PATH,
    );
  }

  const braidThemeName = config.themeName as BraidThemeName;

  return (
    <VocabProvider language={vocabLanguage}>
      <BraidDesignProvider braidThemeName={braidThemeName}>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <AppContext.Provider
            value={{
              language,
              site,
              tenant,
              environment,
              renderConfig: config,
              locale,
              hostConfig,
            }}
          >
            <AppRouter site={site} />
          </AppContext.Provider>
        </IntercomProvider>
      </BraidDesignProvider>
    </VocabProvider>
  );
};
