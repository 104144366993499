import {
  seek_co_nz,
  seek_com_au,
  hk_jobsdb_com,
  th_jobsdb_com,
  my_jobstreet_com,
  ph_jobstreet_com,
  sg_jobstreet_com,
  id_jobstreet_com,
} from '@seek/online-identity';

import type { RenderConfig } from '../../types';

export default {
  hostConfigs: {
    [seek_co_nz.clientDomain]: {
      clientConfig: seek_co_nz,
      environment: {
        SITE: 'SEEK_NZ',
        COUNTRY: 'New Zealand',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
      },
    },
    [seek_com_au.clientDomain]: {
      clientConfig: seek_com_au,
      environment: {
        SITE: 'SEEK_AU',
        COUNTRY: 'Australia',
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
        IMPRESSION_TRACKING_ENABLED: true,
      },
    },
    [th_jobsdb_com.clientDomain]: {
      clientConfig: th_jobsdb_com,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      requireAgeConsent: true,
      environment: {
        SITE: 'JOBSDB_TH',
        COUNTRY: 'Thailand',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
      },
    },
    [hk_jobsdb_com.clientDomain]: {
      clientConfig: hk_jobsdb_com,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {
        SITE: 'JOBSDB_HK',
        COUNTRY: 'Hong Kong',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
      },
    },
    [my_jobstreet_com.clientDomain]: {
      clientConfig: my_jobstreet_com,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {
        SITE: 'JOBSTREET_MY',
        COUNTRY: 'Malaysia',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
      },
    },
    [ph_jobstreet_com.clientDomain]: {
      clientConfig: ph_jobstreet_com,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {
        SITE: 'JOBSTREET_PH',
        COUNTRY: 'Philippines',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
      },
    },
    [sg_jobstreet_com.clientDomain]: {
      clientConfig: sg_jobstreet_com,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {
        SITE: 'JOBSTREET_SG',
        COUNTRY: 'Singapore',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
      },
    },
    [id_jobstreet_com.clientDomain]: {
      clientConfig: id_jobstreet_com,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {
        SITE: 'JOBSTREET_ID',
        COUNTRY: 'Indonesia',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
      },
    },
  },
  hookDomain: 'candidate',
  themeName: 'seekJobs',
  originWhitelist: [
    'https://certsy.com',
    'https://app.certsy.com',
    'https://seekpass.co',
    'https://app.seekpass.co',
  ],
} as RenderConfig;
