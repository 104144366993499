
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IuC4leC5ieC4reC4h%2BC4geC4suC4o%2BC4peC4h%2BC4l%2BC4sOC5gOC4muC4teC4ouC4meC4quC4s%2BC4q%2BC4o%2BC4seC4muC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC5g%2BC4q%2BC4oeC5iOC4q%2BC4o%2BC4t%2BC4reC5hOC4oeC5iD8iLCJzb2NpYWxfY29uZmlybWF0aW9uX19ib2R5X190ZXh0Ijoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJIHticmFuZH0g4Liq4Liz4Lir4Lij4Lix4Lia4Lit4Li14LmA4Lih4LilIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fcmVnaXN0ZXJfX2J1dHRvbiI6IuC4peC4h%2BC4l%2BC4sOC5gOC4muC4teC4ouC4meC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC5g%2BC4q%2BC4oeC5iCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoi4LiB4Lil4Lix4Lia4LmE4Lib4LiX4Li14LmI4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiLguKrguLPguKvguKPguLHguJrguIHguLLguKPguKrguKPguYnguLLguIfguJrguLHguI3guIrguLUg4LiE4Li44LiT4LiV4LmJ4Lit4LiH4Lii4Lit4Lih4Lij4Lix4Lia4LiC4LmJ4Lit4LiB4Liz4Lir4LiZ4LiU4LiC4LmJ4Liy4LiH4LiV4LmJ4LiZIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fcHJpdmFjeV9fdGV4dCI6IkJ5IHJlZ2lzdGVyaW5nLCBJIGFncmVlIHRvIFNFRUvigJlzIDxQcml2YWN5UG9saWN5TGluaz5Qcml2YWN5IFBvbGljeTwvUHJpdmFjeVBvbGljeUxpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IuC4leC5ieC4reC4h%2BC4geC4suC4o%2BC4peC4h%2BC4l%2BC4sOC5gOC4muC4teC4ouC4meC4quC4s%2BC4q%2BC4o%2BC4seC4muC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC5g%2BC4q%2BC4oeC5iOC4q%2BC4o%2BC4t%2BC4reC5hOC4oeC5iD8iLCJzb2NpYWxfY29uZmlybWF0aW9uX19ib2R5X190ZXh0Ijoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJIHticmFuZH0g4Liq4Liz4Lir4Lij4Lix4Lia4Lit4Li14LmA4Lih4LilIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fcmVnaXN0ZXJfX2J1dHRvbiI6IuC4peC4h%2BC4l%2BC4sOC5gOC4muC4teC4ouC4meC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC5g%2BC4q%2BC4oeC5iCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoi4LiB4Lil4Lix4Lia4LmE4Lib4LiX4Li14LmI4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiLguKrguLPguKvguKPguLHguJrguIHguLLguKPguKrguKPguYnguLLguIfguJrguLHguI3guIrguLUg4LiE4Li44LiT4LiV4LmJ4Lit4LiH4Lii4Lit4Lih4Lij4Lix4Lia4LiC4LmJ4Lit4LiB4Liz4Lir4LiZ4LiU4LiC4LmJ4Liy4LiH4LiV4LmJ4LiZIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fcHJpdmFjeV9fdGV4dCI6IkJ5IHJlZ2lzdGVyaW5nLCBJIGFncmVlIHRvIFNFRUvigJlzIDxQcml2YWN5UG9saWN5TGluaz5Qcml2YWN5IFBvbGljeTwvUHJpdmFjeVBvbGljeUxpbms%2BIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IkJ1YXQgYWt1biBiYXJ1PyIsInNvY2lhbF9jb25maXJtYXRpb25fX2JvZHlfX3RleHQiOiJLYW1pIHRpZGFrIG1lbmVtdWthbiBha3VuIHticmFuZH0gdW50dWsiLCJzb2NpYWxfY29uZmlybWF0aW9uX19yZWdpc3Rlcl9fYnV0dG9uIjoiQnVhdCBha3VuIGJhcnUiLCJzb2NpYWxfY29uZmlybWF0aW9uX19iYWNrX2J1dHRvbiI6IktlbWJhbGkga2UgaGFsYW1hbiBtYXN1ayIsInNvY2lhbF9jb25maXJtYXRpb25fX2NvbnNlbnRfX2Vycm9yIjoiVW50dWsgbWVtYnVhdCBha3VuLCBBbmRhIGhhcnVzIG1lbnlldHVqdWkga2V0ZW50dWFuIGRpIGF0YXMiLCJzb2NpYWxfY29uZmlybWF0aW9uX19wcml2YWN5X190ZXh0IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS%2BKAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IkJ1YXQgYWt1biBiYXJ1PyIsInNvY2lhbF9jb25maXJtYXRpb25fX2JvZHlfX3RleHQiOiJLYW1pIHRpZGFrIG1lbmVtdWthbiBha3VuIHticmFuZH0gdW50dWsiLCJzb2NpYWxfY29uZmlybWF0aW9uX19yZWdpc3Rlcl9fYnV0dG9uIjoiQnVhdCBha3VuIGJhcnUiLCJzb2NpYWxfY29uZmlybWF0aW9uX19iYWNrX2J1dHRvbiI6IktlbWJhbGkga2UgaGFsYW1hbiBtYXN1ayIsInNvY2lhbF9jb25maXJtYXRpb25fX2NvbnNlbnRfX2Vycm9yIjoiVW50dWsgbWVtYnVhdCBha3VuLCBBbmRhIGhhcnVzIG1lbnlldHVqdWkga2V0ZW50dWFuIGRpIGF0YXMiLCJzb2NpYWxfY29uZmlybWF0aW9uX19wcml2YWN5X190ZXh0IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS%2BKAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IuC4leC5ieC4reC4h%2BC4geC4suC4o%2BC4peC4h%2BC4l%2BC4sOC5gOC4muC4teC4ouC4meC4quC4s%2BC4q%2BC4o%2BC4seC4muC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC5g%2BC4q%2BC4oeC5iOC4q%2BC4o%2BC4t%2BC4reC5hOC4oeC5iD8iLCJzb2NpYWxfY29uZmlybWF0aW9uX19ib2R5X190ZXh0Ijoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJIHticmFuZH0g4Liq4Liz4Lir4Lij4Lix4Lia4Lit4Li14LmA4Lih4LilIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fcmVnaXN0ZXJfX2J1dHRvbiI6IuC4peC4h%2BC4l%2BC4sOC5gOC4muC4teC4ouC4meC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC5g%2BC4q%2BC4oeC5iCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoi4LiB4Lil4Lix4Lia4LmE4Lib4LiX4Li14LmI4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiLguKrguLPguKvguKPguLHguJrguIHguLLguKPguKrguKPguYnguLLguIfguJrguLHguI3guIrguLUg4LiE4Li44LiT4LiV4LmJ4Lit4LiH4Lii4Lit4Lih4Lij4Lix4Lia4LiC4LmJ4Lit4LiB4Liz4Lir4LiZ4LiU4LiC4LmJ4Liy4LiH4LiV4LmJ4LiZIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fcHJpdmFjeV9fdGV4dCI6IkJ5IHJlZ2lzdGVyaW5nLCBJIGFncmVlIHRvIFNFRUvigJlzIDxQcml2YWN5UG9saWN5TGluaz5Qcml2YWN5IFBvbGljeTwvUHJpdmFjeVBvbGljeUxpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IuC4leC5ieC4reC4h%2BC4geC4suC4o%2BC4peC4h%2BC4l%2BC4sOC5gOC4muC4teC4ouC4meC4quC4s%2BC4q%2BC4o%2BC4seC4muC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC5g%2BC4q%2BC4oeC5iOC4q%2BC4o%2BC4t%2BC4reC5hOC4oeC5iD8iLCJzb2NpYWxfY29uZmlybWF0aW9uX19ib2R5X190ZXh0Ijoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJIHticmFuZH0g4Liq4Liz4Lir4Lij4Lix4Lia4Lit4Li14LmA4Lih4LilIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fcmVnaXN0ZXJfX2J1dHRvbiI6IuC4peC4h%2BC4l%2BC4sOC5gOC4muC4teC4ouC4meC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC5g%2BC4q%2BC4oeC5iCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoi4LiB4Lil4Lix4Lia4LmE4Lib4LiX4Li14LmI4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiLguKrguLPguKvguKPguLHguJrguIHguLLguKPguKrguKPguYnguLLguIfguJrguLHguI3guIrguLUg4LiE4Li44LiT4LiV4LmJ4Lit4LiH4Lii4Lit4Lih4Lij4Lix4Lia4LiC4LmJ4Lit4LiB4Liz4Lir4LiZ4LiU4LiC4LmJ4Liy4LiH4LiV4LmJ4LiZIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fcHJpdmFjeV9fdGV4dCI6IkJ5IHJlZ2lzdGVyaW5nLCBJIGFncmVlIHRvIFNFRUvigJlzIDxQcml2YWN5UG9saWN5TGluaz5Qcml2YWN5IFBvbGljeTwvUHJpdmFjeVBvbGljeUxpbms%2BIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IkJ1YXQgYWt1biBiYXJ1PyIsInNvY2lhbF9jb25maXJtYXRpb25fX2JvZHlfX3RleHQiOiJLYW1pIHRpZGFrIG1lbmVtdWthbiBha3VuIHticmFuZH0gdW50dWsiLCJzb2NpYWxfY29uZmlybWF0aW9uX19yZWdpc3Rlcl9fYnV0dG9uIjoiQnVhdCBha3VuIGJhcnUiLCJzb2NpYWxfY29uZmlybWF0aW9uX19iYWNrX2J1dHRvbiI6IktlbWJhbGkga2UgaGFsYW1hbiBtYXN1ayIsInNvY2lhbF9jb25maXJtYXRpb25fX2NvbnNlbnRfX2Vycm9yIjoiVW50dWsgbWVtYnVhdCBha3VuLCBBbmRhIGhhcnVzIG1lbnlldHVqdWkga2V0ZW50dWFuIGRpIGF0YXMiLCJzb2NpYWxfY29uZmlybWF0aW9uX19wcml2YWN5X190ZXh0IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS%2BKAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IkJ1YXQgYWt1biBiYXJ1PyIsInNvY2lhbF9jb25maXJtYXRpb25fX2JvZHlfX3RleHQiOiJLYW1pIHRpZGFrIG1lbmVtdWthbiBha3VuIHticmFuZH0gdW50dWsiLCJzb2NpYWxfY29uZmlybWF0aW9uX19yZWdpc3Rlcl9fYnV0dG9uIjoiQnVhdCBha3VuIGJhcnUiLCJzb2NpYWxfY29uZmlybWF0aW9uX19iYWNrX2J1dHRvbiI6IktlbWJhbGkga2UgaGFsYW1hbiBtYXN1ayIsInNvY2lhbF9jb25maXJtYXRpb25fX2NvbnNlbnRfX2Vycm9yIjoiVW50dWsgbWVtYnVhdCBha3VuLCBBbmRhIGhhcnVzIG1lbnlldHVqdWkga2V0ZW50dWFuIGRpIGF0YXMiLCJzb2NpYWxfY29uZmlybWF0aW9uX19wcml2YWN5X190ZXh0IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS%2BKAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlJlZ2lzdGVyIGEgbmV3IGFjY291bnQ%2FIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsInNvY2lhbF9jb25maXJtYXRpb25fX3JlZ2lzdGVyX19idXR0b24iOiJSZWdpc3RlciBuZXcgYWNjb3VudCIsInNvY2lhbF9jb25maXJtYXRpb25fX2JhY2tfYnV0dG9uIjoiQmFjayB0byBzaWduIGluIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiIsInNvY2lhbF9jb25maXJtYXRpb25fX3ByaXZhY3lfX3RleHQiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlvFmOG6veG6veG6vcSjw6zDrMOsxaHhua%2Fhur3hur3hur3FmSDEg8SDxIMg4LiB4Li14LmJ4bq94bq94bq9xbUgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5rz9dIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IlvFtOG6veG6veG6vSDGjMOsw6zDrMaM4LiB4Li14LmJJ%2BG5ryDGksOsw6zDrOC4geC4teC5icaMIMSDxIPEgyB7YnJhbmR9IMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8gxpLDtsO2w7bFmV0iLCJzb2NpYWxfY29uZmlybWF0aW9uX19yZWdpc3Rlcl9fYnV0dG9uIjoiW8WY4bq94bq94bq9xKPDrMOsw6zFoeG5r%2BG6veG6veG6vcWZIOC4geC4teC5ieG6veG6veG6vcW1IMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua9dIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYmFja19idXR0b24iOiJb4bqexIPEg8SDw6fEtyDhua%2FDtsO2w7YgxaHDrMOsw6zEo%2BC4geC4teC5iSDDrMOsw6zguIHguLXguYldIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJb4bmuw7bDtsO2IMOnxZnhur3hur3hur3Eg8SDxIPhua%2Fhur3hur3hur0gxIPEg8SD4LiB4Li14LmJIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8sIMO9w73DvcO2w7bDtseax5rHmiBtzILHmseax5rFoeG5ryDEg8SDxIPEo8WZ4bq94bq94bq94bq94bq94bq9IOG5r8O2w7bDtiDhua%2FhuKnhur3hur3hur0gxIPEg8SDw5%2FDtsO2w7bhub3hur3hur3hur0g4bmv4bq94bq94bq9xZltzILFoS5dIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fcHJpdmFjeV9fdGV4dCI6Ilvhup7DvcO9w70gxZnhur3hur3hur3Eo8Osw6zDrMWh4bmv4bq94bq94bq9xZnDrMOsw6zguIHguLXguYnEoywgw48gxIPEg8SDxKPFmeG6veG6veG6veG6veG6veG6vSDhua%2FDtsO2w7YgxaDDi8OL4biw4oCZxaEgPFByaXZhY3lQb2xpY3lMaW5rPsakxZnDrMOs4bm9xIPEg8Onw73DvSDGpMO2w7bGmsOsw6zDp8O9w708L1ByaXZhY3lQb2xpY3lMaW5rPl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzb2NpYWxfY29uZmlybWF0aW9uX190aXRsZV9fdGV4dCI6IlvFmOG6veG6veG6vcSjw6zDrMOsxaHhua%2Fhur3hur3hur3FmSDEg8SDxIMg4LiB4Li14LmJ4bq94bq94bq9xbUgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5rz9dIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYm9keV9fdGV4dCI6IlvFtOG6veG6veG6vSDGjMOsw6zDrMaM4LiB4Li14LmJJ%2BG5ryDGksOsw6zDrOC4geC4teC5icaMIMSDxIPEgyB7YnJhbmR9IMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8gxpLDtsO2w7bFmV0iLCJzb2NpYWxfY29uZmlybWF0aW9uX19yZWdpc3Rlcl9fYnV0dG9uIjoiW8WY4bq94bq94bq9xKPDrMOsw6zFoeG5r%2BG6veG6veG6vcWZIOC4geC4teC5ieG6veG6veG6vcW1IMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua9dIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fYmFja19idXR0b24iOiJb4bqexIPEg8SDw6fEtyDhua%2FDtsO2w7YgxaHDrMOsw6zEo%2BC4geC4teC5iSDDrMOsw6zguIHguLXguYldIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fY29uc2VudF9fZXJyb3IiOiJb4bmuw7bDtsO2IMOnxZnhur3hur3hur3Eg8SDxIPhua%2Fhur3hur3hur0gxIPEg8SD4LiB4Li14LmJIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8sIMO9w73DvcO2w7bDtseax5rHmiBtzILHmseax5rFoeG5ryDEg8SDxIPEo8WZ4bq94bq94bq94bq94bq94bq9IOG5r8O2w7bDtiDhua%2FhuKnhur3hur3hur0gxIPEg8SDw5%2FDtsO2w7bhub3hur3hur3hur0g4bmv4bq94bq94bq9xZltzILFoS5dIiwic29jaWFsX2NvbmZpcm1hdGlvbl9fcHJpdmFjeV9fdGV4dCI6Ilvhup7DvcO9w70gxZnhur3hur3hur3Eo8Osw6zDrMWh4bmv4bq94bq94bq9xZnDrMOsw6zguIHguLXguYnEoywgw48gxIPEg8SDxKPFmeG6veG6veG6veG6veG6veG6vSDhua%2FDtsO2w7YgxaDDi8OL4biw4oCZxaEgPFByaXZhY3lQb2xpY3lMaW5rPsakxZnDrMOs4bm9xIPEg8Onw73DvSDGpMO2w7bGmsOsw6zDp8O9w708L1ByaXZhY3lQb2xpY3lMaW5rPl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    